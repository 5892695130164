import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  Button,
} from '@material-ui/core'

import Layout from '../components/Layout'
import confirmImg from '../img/confirmed.png'
import PageContainer from '../components/PageContainer'
import { getNavigatorToLink } from '../utils/navigate'

const styles = () => {
  return createStyles({
    container: {
      marginBottom: '2rem',
    },
    image: {
      width: '100%',
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Confirm = ({ classes }: Props) => {
  const handleClick = getNavigatorToLink('/')

  return (
    <Layout>
      <PageContainer className={classes.container}>
        <Typography variant="h1">Success!</Typography>
        <Typography>
          You have successfully sent your message! Expect a reply within{' '}
          <strong>24 hours.</strong>
        </Typography>
        <img
          src={confirmImg}
          alt="Hand holding phone with checkmark symbol."
          className={classes.image}
        />
        <Button
          href="/"
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          Go Home
        </Button>
      </PageContainer>
    </Layout>
  )
}

export default withStyles(styles)(Confirm)
